import 'lazysizes';
// import smoothscroll from 'smoothscroll-polyfill';

import 'widok';

// smoothscroll.polyfill();

// document.addEventListener('lazybeforeunveil', event => {
//   const bg = event.target.getAttribute('data-bg');
//   if (bg) event.target.style.backgroundImage = 'url(' + bg + ')';
// });